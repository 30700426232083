import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Setup",
  "path": "/setup",
  "date": "05-07-2020"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bitwarden.com/#download"
        }}>{`BitWarden`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.google.com/intl/en/chrome/"
        }}>{`Chrome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/new/"
        }}>{`Firefox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/"
        }}>{`VSCode`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.alfredapp.com/"
        }}>{`Alfred`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kapeli.com/dash"
        }}>{`Dash`}</a></li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.notion.so/"
        }}>{`Notion`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.xmind.net/download/"
        }}>{`Xmind`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://freedom.to/"
        }}>{`Freedom`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dropbox.com/downloading"
        }}>{`Dropbox`}</a></li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Brew:`}</p>
        <p parentName="li"><inlineCode parentName="p">{`/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/master/install.sh)"`}</inlineCode></p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`OMZSH:`}</p>
        <p parentName="li"><inlineCode parentName="p">{`/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/ohmyzsh/ohmyzsh/master/tools/install.sh)"`}</inlineCode></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      